/* You can add global styles to this file, and also import other style files */

html {
  font-family: "Roboto", sans-serif;
}
.wrapper {
  margin-top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .loading {
    height: 100px;
    animation: spin 1.5s linear infinite;
  }
  .img {
    width: 10rem;
    margin: 1rem auto 0;

    img {
      width: 100%;
      display: block;
    }
  }

  h1 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    display: flex;
    color: #f44336;
    width: 70%;
    margin: auto;
    text-align: center;
    justify-content: center;
    line-height: 2rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
